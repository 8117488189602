import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactExport from 'react-export-excel';

import { Form } from '@unform/web';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { FiCopy, FiLink } from 'react-icons/fi';
import { read, utils } from 'xlsx';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import Table from '../../components/DevExpressTable/DevExpressTable';

import { useToast } from '../../hooks/toast';
import { columns } from './columns';
import { columnsLinks } from './columnsLinks';
import BackButton from '../../components/BackButton';
import {
  Container,
  Content,
  Button,
  ExpandedDiv,
  AddIcon,
  Hr,
  DivButtonCancelDialog,
} from './styles';
import api from '../../services/api';
import TableRows from './tableRows';
import EnumProductSubGroup from '../../utils/enums/EnumProductSubGroup';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

interface AccessFile {
  SurveyID: string;
  AccessName: string;
  Segments: string;
}

interface Access {
  accessId: number;
  name: string;
  results: any;
  segments: string[];
  isLocked: boolean;
  surveyName: string;
  subgroup: string;
  languages: number[];
}

interface ParamTypes {
  surveyId: string;
}

interface State {
  surveyName: string;
  subgroup: string;
  pageTitle: string;
  isTemplate: boolean;
  languages?: number[];
}

interface CodeLink {
  resultId: number;
  code: string;
  url: string;
  createdByUser: boolean;
  surveyId: number;
  surveyName: string;
  segmentNames: string;
  linkName: string;
  placeholders: Placeholder[];
  subgroup: string;
  accessId: number;
  languages: number[];
}

interface Placeholder {
  replaceValue: string;
  searchValue: string;
}

interface SelectProps {
  value: number;
  label: string;
}

interface LinksDialog {
  languageId: number;
  languageName: string;
  linkUrl: string;
}

const Accesses: React.FC = () => {
  const { addToast } = useToast();
  // const [codeLinks, setCodeLinks] = useState<CodeLink[]>([]);

  const [codeLinksDownloadAllCodes, setCodeLinksDownloadAllCodes] = useState<
    CodeLink[]
  >([]);

  const [accesses, setAccesses] = useState<Access[]>([]);

  const [accessFile, setAccessFile] = useState<AccessFile[]>();
  const [showUploadFields, setShowUploadFields] = useState<boolean>(false);
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
  const [errorsFromUploadedFile, setErrorsFromUploadedFile] = useState<
    string[]
  >([]);

  const { surveyId } = useParams<ParamTypes>();
  const { state } = useLocation<State>();
  const [placeholderHidden, setPlaceholderHidden] = useState<boolean>(true);

  const [rowsData, setRowsData] = useState<any[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [surveyIsLocked, setSurveyIsLocked] = useState<boolean>(false);

  const [surveyIsTemplate, setSurveyIsTemplate] = useState<boolean>(false);

  const [tableColumnExtensions] = useState([
    { columnName: 'accessId', width: 100 },
    { columnName: 'name', width: 300 },
    { columnName: 'status', width: 100, showSortingControls: false },
  ]);

  const [open, setOpen] = useState<boolean>(false);

  const [urlLinksDialog, setUrlLinksDialog] = useState<LinksDialog[]>([]);

  const handleClickUrlCopy = useCallback((row: any) => {
    let host = `${window.location.hostname}`;

    if (host === 'localhost') {
      host = 'http://localhost:3000';
    } else {
      host = `https://${host}`;
    }
    const completeLink = `${host}${row.linkUrl}`;

    navigator.clipboard.writeText(`${completeLink}`);
  }, []);

  const handleClickOpen = useCallback(
    async (row: any) => {
      const languagesDropDown: SelectProps[] = [];
      const languagesCodes: SelectProps[] = [];
      await api.get(`languages/`).then(response => {
        response.data.forEach((e: any) => {
          const dataRowDropDown = {
            value: e?.languageId,
            label: e?.languageId === 1 ? 'German (Default)' : e?.name,
          };
          languagesDropDown.push(dataRowDropDown);
          languagesCodes.push({ value: e?.languageId, label: e?.languageCode });
        });
      });

      const languagesSurveyArray: number[] = [];

      await api.get(`/surveys/${surveyId}`).then(response => {
        languagesSurveyArray.push(response.data.defaultLanguageId);
        response.data?.languages?.forEach((c: any) => {
          languagesSurveyArray.push(c);
        });
        const urlLinksAux: LinksDialog[] = [];

        languagesSurveyArray.forEach(languageId => {
          urlLinksAux.push({
            languageId,
            languageName:
              languagesDropDown.find(l => l.value === languageId)?.label ??
              'error',
            linkUrl: `/questionnaire/${
              row.accessId
            }?lang=${languageId}&langCode=${
              languagesCodes.find(x => x.value === languageId)?.label ?? 'de'
            }`,
          });
        });

        setUrlLinksDialog(urlLinksAux);
        setOpen(true);
      });
    },
    [surveyId],
  );

  const [actionColumnsFinalLink] = useState<IActionColumn[]>([
    {
      columnName: 'finalLink',
      label: 'Final Link',
      onClick: handleClickOpen,
      icon: <FiLink size={22} color={'#ff0000'} />,
      width: 150,
    },
  ]);

  const [actionColumnsUrlLanguages] = useState<IActionColumn[]>([
    {
      columnName: 'copyUrl',
      label: 'Copy',
      onClick: handleClickUrlCopy,
      icon: <FiCopy />,
    },
  ]);

  const [tableColumnLanguagesExtensions] = useState([
    { columnName: 'languageName', width: 300 },
  ]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const delay = (ms: any): Promise<void> =>
    new Promise(resolve => setTimeout(resolve, ms));

  const handlePlaceholderButton = useCallback(() => {
    setPlaceholderHidden(!placeholderHidden);
  }, [placeholderHidden]);

  const addTableRows = useCallback(() => {
    const rowsInput: Placeholder = {
      searchValue: '',
      replaceValue: '',
    };

    setRowsData([...rowsData, rowsInput]);
  }, [rowsData]);

  const deleteTableRows = useCallback(
    async (index: any) => {
      const rows = [...rowsData];
      rows.splice(index, 1);
      setRowsData(rows);
    },
    [rowsData],
  );

  const handleChange = useCallback(
    async (index, evnt) => {
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    },
    [rowsData],
  );

  const handleSubmit = useCallback(async () => {
    setPlaceholderHidden(!placeholderHidden); // hide formPlaceholder

    try {
      const placeholderParams = {
        idSurvey: Number(surveyId),
        placeholders: rowsData,
      };

      await api.put(
        `/accesses/placeholder/survey/${placeholderParams.idSurvey}`,
        placeholderParams,
      );

      addToast({
        type: 'success',
        title: 'Success',
        description: 'All accesses were saved successfully!',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Registration Error',
        description:
          'An error occurred while editing the access, please try again.',
      });
    }
  }, [addToast, placeholderHidden, rowsData, surveyId]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const response = await api.get(`/accesses/codes/${surveyId}`);
      const localLinksDownloadAllCodes: CodeLink[] = [];
      response.data.forEach((accessData: any) => {
        const resultsByAccess = accessData.results?.filter(
          (x: any) => x.accessId === accessData.accessId,
        );

        resultsByAccess.forEach((result: CodeLink) => {
          localLinksDownloadAllCodes.push({
            resultId: result.resultId,
            createdByUser: result.createdByUser,
            code: result.code,
            url: `https://${window.location.host}/questionnaire/${accessData.accessId}/${result.code}`,
            linkName: accessData.name ?? '',
            segmentNames: JSON.stringify(accessData.segments),
            surveyName: state?.surveyName,
            surveyId: Number(surveyId),
            placeholders: result.placeholders,
            subgroup: state?.subgroup,
            accessId: result.accessId,
            languages: state?.languages ?? [],
          });
        });
      });

      setCodeLinksDownloadAllCodes(localLinksDownloadAllCodes);
      await delay(1000);
    }
    fetchData();
  }, [state?.languages, state?.subgroup, state?.surveyName, surveyId]);

  // getAllData
  const getData = useCallback(() => {
    async function fetchData(): Promise<void> {
      const surveyResponse = await api.get(`/surveys/${surveyId}`);
      const isTemplate = surveyResponse?.data?.isTemplate;
      setSurveyIsTemplate(isTemplate);
      if (state?.pageTitle !== '') {
        setPageTitle(state?.pageTitle);
      } else if (surveyResponse && surveyResponse.data) {
        const subGroupName: string =
          Object.values(EnumProductSubGroup)[
            Object.keys(EnumProductSubGroup).indexOf(
              surveyResponse.data.subGroup ? surveyResponse.data.subGroup : '',
            )
          ];

        if (surveyResponse.data.isTemplate) {
          const title = `${surveyResponse.data.name} - ${subGroupName}`;
          setPageTitle(title);
        } else {
          const title = `${surveyResponse.data.projectName} - ${surveyResponse.data.name} - ${subGroupName}`;
          setPageTitle(title);
        }
      }

      if (
        surveyResponse &&
        surveyResponse.data &&
        surveyResponse.data?.isLocked === true
      ) {
        setSurveyIsLocked(true);
      } else {
        setSurveyIsLocked(false);
      }

      const response = await api.get(`/accesses/survey/${surveyId}`);
      const localAccessData: Access[] = [];

      // if there's no access, create a default one.
      if (response.data.length === 0) {
        const access = {
          name: 'Default Link',
          surveyId,
          isLocked: isTemplate,
        };

        await api.post('/accesses', access).then(async () => {
          const responseWithNewData = await api.get(
            `/accesses/survey/${surveyId}`,
          );
          responseWithNewData.data.forEach((element: Access) => {
            localAccessData.push({
              ...element,
              surveyName: state?.surveyName,
              subgroup: state?.subgroup,
              languages: surveyResponse?.data?.languages,
            });
          });
          localAccessData.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
          setAccesses(localAccessData);
        });
      } else {
        response.data.forEach((element: Access) => {
          localAccessData.push({
            ...element,
            surveyName: state?.surveyName,
            subgroup: state?.subgroup,
            languages: surveyResponse?.data?.languages,
          });
        });
        localAccessData.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
        setAccesses(localAccessData);
      }
    }

    fetchData();
  }, [state?.surveyName, state?.subgroup, state?.pageTitle, surveyId]);

  useEffect(() => {
    getData();
  }, [getData, state?.pageTitle, state?.subgroup, state?.surveyName, surveyId]);

  useEffect(() => {
    setPageTitle(state?.pageTitle);
  }, [state?.pageTitle]);

  const handleLockAllButton = useCallback(async () => {
    try {
      await api.put(`/accesses/lockAll/survey/${surveyId}`);

      getData();

      addToast({
        type: 'success',
        title: 'Success',
        description: 'All accesses are locked.',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Registration Error',
        description:
          'An error occurred while editing the access, please try again.',
      });
    }
  }, [addToast, getData, surveyId]);

  const handleUploadButton = useCallback(async () => {
    setShowUploadFields(!showUploadFields);
  }, [showUploadFields]);

  async function Validation(data: AccessFile[]): Promise<string[]> {
    if (!data) {
      return ['No data on file'];
    }

    const errors = [];

    // check surveyID
    data.forEach((element: AccessFile) => {
      if (element.SurveyID) {
        // eslint-disable-next-line eqeqeq
        if (element.SurveyID != surveyId) {
          errors.push(
            `You can not add links for survey ${element.SurveyID} from survey ${surveyId}`,
          );
        }
      } else {
        errors.push(`SurveyID is required in all rows`);
      }
    });

    // check accessName:
    const accessNames = data.map((accessObj: AccessFile) => {
      if (!accessObj.AccessName) {
        errors.push(`AccessName is required in all rows`);
      }
      return accessObj.AccessName;
    });

    const findDuplicates = (arr: string[]): string[] =>
      arr.filter((item, index) => arr.indexOf(item) !== index);

    const duplicates = findDuplicates(accessNames);
    if (duplicates.length > 0) {
      errors.push(`Duplicate accessName: ${duplicates.join(',')}`);
    }

    // Check segments
    const uniqueSegmentsFromFile = [
      ...new Set(
        data.flatMap((access: AccessFile) => {
          return (
            access?.Segments?.split(',').map((x: string) => {
              return x.trim();
            }) ?? []
          );
        }),
      ),
    ];

    const segmentsDB = await api.get('/segments');

    if (segmentsDB.data) {
      const segments = segmentsDB.data.map((segment: any) => {
        return segment.name.trim();
      });

      const segmentsNotInDataBase = uniqueSegmentsFromFile
        .map((element: any) => {
          return segments.includes(element.trim()) ? undefined : element.trim();
        })
        ?.filter(x => x !== undefined);

      if (segmentsNotInDataBase.length > 0)
        errors.push(
          ` Segments do not exist in database: ${segmentsNotInDataBase.join(
            ',',
          )}`,
        );
      else {
        return errors;
      }
    } else {
      errors.push('Enable to fetch segments from database');
    }
    return errors;
  }

  const handleUploadFile = useCallback(
    async (e: any): Promise<void> => {
      try {
        const file = e.target.files[0];
        const f = await file.arrayBuffer();

        const wb = read(f);
        const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
        const data = utils.sheet_to_json<AccessFile>(ws); // generate objects

        setIsFileSelected(true);
        setAccessFile(data);
        setErrorsFromUploadedFile([]);

        const validations = await Validation(data);
        if (validations.length === 0) {
          addToast({
            type: 'info',
            title: 'Check the data',
            description: 'Check the data and confirm.',
          });
        } else {
          setErrorsFromUploadedFile(validations);
        }
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while create the access, please try again.',
        });
      }
    },
    [Validation, addToast],
  );

  const handleCreateAccessFromFile = useCallback(async (): Promise<void> => {
    try {
      const accessFileFixed = accessFile?.map(y => {
        return {
          ...y,
          Segments:
            y.Segments?.split(',')?.map((x: string) => {
              return x.trim();
            }) ?? [],
        };
      });
      await api.post(`/accesses/insertMany/`, {
        list: accessFileFixed,
      });

      // refresh data grid
      getData();

      // reset fields from upload
      setShowUploadFields(!showUploadFields);
      setIsFileSelected(false);
      setAccessFile([]);
      setErrorsFromUploadedFile([]);
      addToast({
        type: 'success',
        title: 'Success',
        description: 'Accesses imported.',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Registration Error',
        description:
          'An error occurred while create the access, please try again.',
      });
    }
  }, [accessFile, addToast, getData, showUploadFields]);

  const handleDeleteTestCodes = useCallback(() => {
    api.delete(`/results/testResults/${surveyId}`).then(response => {
      addToast({
        type: 'success',
        title: 'Success',
        description: 'All test results were deleted successfully!',
      });
    });
  }, [addToast, surveyId]);

  return (
    <Container>
      <BackButton url={`/surveys/${surveyId}`} />
      <h1>Links</h1>
      <h2>{pageTitle}</h2>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Links with translations</DialogTitle>
        <DialogContent>
          <Table
            columnsProp={columnsLinks}
            dataProp={urlLinksDialog}
            checkboxSelection={false}
            tableColumnExtensions={tableColumnLanguagesExtensions}
            hasFilterRow={false}
            idName={'accessId'}
            actionColumns={actionColumnsUrlLanguages}
            hiddenFooter={true}
          />
        </DialogContent>
        <DivButtonCancelDialog>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              autoFocus
              type={'button'}
            >
              Cancel
            </Button>
          </DialogActions>
        </DivButtonCancelDialog>
      </Dialog>

      {!surveyIsLocked && (
        <Link to={`/surveys/${surveyId}/accesses/new`}>
          <Button variant="contained" type="button">
            New Link
          </Button>
        </Link>
      )}

      {!surveyIsTemplate && (
        <ExcelFile
          element={<Button>Download All Codes</Button>}
          filename={`Codes`}
        >
          <ExcelSheet data={codeLinksDownloadAllCodes} name="Codes">
            <ExcelColumn label="ID" value="resultId" />
            <ExcelColumn label="Access" value="accessId" />
            <ExcelColumn label="Access Name" value="linkName" />
            <ExcelColumn label="User Group" value="subgroup" />
            <ExcelColumn label="Survey" value="surveyName" />
            <ExcelColumn label="Code" value="code" />
            <ExcelColumn label="Segments" value="segmentNames" />
            <ExcelColumn label="URL" value="url" />
            <ExcelColumn label="Created By User" value="createdByUser" />
          </ExcelSheet>
        </ExcelFile>
      )}

      {!surveyIsLocked && (
        <Button
          variant="contained"
          type="button"
          onClick={handlePlaceholderButton}
        >
          Placeholder
        </Button>
      )}

      <Button
        style={{ width: 'fit-content' }}
        variant="contained"
        type="button"
        onClick={handleDeleteTestCodes}
      >
        Delete Test Results
      </Button>

      <Button variant="contained" type="button" onClick={handleLockAllButton}>
        Lock all
      </Button>

      <Button variant="contained" type="button" onClick={handleUploadButton}>
        Upload
      </Button>

      <div hidden={placeholderHidden}>
        <ExpandedDiv>
          <Form
            // ref={placeholderformRef}
            // initialData={placeholderForm}
            onSubmit={handleSubmit}
          >
            <h2>Placeholder</h2>
            <div
              style={{
                margin: '1rem',
                //  backgroundColor: '#e0e0e0'
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Text to be replaced</th>
                    <th>Text to replace</th>
                    <th style={{ textAlign: 'left' }}>
                      <button onClick={addTableRows} type={'button'}>
                        <AddIcon></AddIcon>
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <Hr></Hr>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <TableRows
                    rowsData={rowsData}
                    deleteTableRows={deleteTableRows}
                    handleChange={handleChange}
                  ></TableRows>
                </tbody>
              </table>
            </div>

            <Button variant="contained" type="submit">
              Apply for all
            </Button>
          </Form>
        </ExpandedDiv>
      </div>

      <div hidden={!showUploadFields}>
        <ExpandedDiv>
          <h2>Upload</h2>
          <h4 style={{ color: 'grey', marginLeft: '10px' }}>
            Expected columns: SurveyID AccessName Segments
          </h4>
          <div style={{ marginLeft: '10px' }}>
            <div className="App">
              <div className="upload-btn-wrapper">
                <input type="file" name="myfile" onChange={handleUploadFile} />
              </div>
              <br />
            </div>
          </div>

          {isFileSelected && (
            <>
              <table>
                <thead>
                  <tr>
                    <th>SurveyID</th>
                    <th>AccessName</th>
                    <th>Segments</th>
                  </tr>
                </thead>
                <tbody>
                  {accessFile?.map((t: AccessFile, index: number) => (
                    <tr key={t.AccessName + index}>
                      <td style={{ textAlign: 'center' }}>{t.SurveyID}</td>
                      <td style={{ textAlign: 'center' }}>{t.AccessName}</td>
                      <td style={{ textAlign: 'center' }}>{t.Segments}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {errorsFromUploadedFile.length === 0 ? (
                <Button
                  variant="contained"
                  type="button"
                  onClick={handleCreateAccessFromFile}
                >
                  Confirm
                </Button>
              ) : (
                <div
                  style={{
                    margin: '20px 10px 10px 10px',
                    padding: '10px 10px 15px 10px',
                    borderRadius: '8px',
                    backgroundColor: '#fddede',
                    color: '#c53030',
                  }}
                >
                  <>
                    <h3>Errors:</h3>
                    <ul>
                      {errorsFromUploadedFile.length > 0 &&
                        errorsFromUploadedFile.map(error => {
                          return (
                            <li style={{ marginLeft: '40px' }} key={error}>
                              {error}
                            </li>
                          );
                        })}
                    </ul>
                  </>
                </div>
              )}
            </>
          )}
        </ExpandedDiv>
      </div>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={accesses}
          checkboxSelection={false}
          tableColumnExtensions={tableColumnExtensions}
          hasFilterRow={false}
          idName={'accessId'}
          actionColumns={actionColumnsFinalLink}
        />
      </Content>
    </Container>
  );
};

export default Accesses;
