import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Select from '../../../components/SelectForm';
import { Container } from './styles';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Input from '../../../components/FormInput';
import ProgressStepBar from '../../../components/ProgressStepBar';
import { Content } from '../styles';

interface SelectType {
  value: number;
  label: string;
}
interface ParticipationLink {
  name: string;
  customerId: number;
  projectId: number;
  surveyId: number;
  accessId: number;
  segmentId: number;
}

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: ParticipationLink;
  handleSubmit: SubmitHandler;
  isEdit?: boolean;
}
const steps = [
  {
    label: 'Cutomer',
    step: 1,
  },
  {
    label: 'Project',
    step: 2,
  },
  {
    label: 'Survey',
    step: 3,
  },
];
const ParticipationLinkForm: React.FC<Props> = props => {
  const surveySelectField = useRef<any>(null);

  const [customerList, setCustomerList] = useState<SelectType[]>([]);
  const [customerSelectedState, setCustomerSelectedState] = useState(0);

  const [projectListSelect, setProjectListSelect] = useState<SelectType[]>([]);
  const [projectSelectedState, setProjectSelectedState] = useState(0);

  const [surveyListSelect, setSurveyListSelect] = useState<SelectType[]>([]);
  const [surveySelectedState, setSurveySelectedState] = useState(0);

  const [segmentList, setSegmentList] = useState<SelectType[]>([]);
  const [segmentSelectedState, setSegmentSelectedState] = useState(0);

  const [accessFullList, setAccessFullList] = useState<any[]>([]);
  const [accessList, setAccessList] = useState<SelectType[]>([]);
  const [accessSelectedState, setAccessSelectedState] = useState(0);

  const [progress, setProgress] = useState<number>(0);

  // getCustomers
  useEffect(() => {
    api.get(`/customers`).then(response => {
      const customersResult = response.data.map((x: any) => {
        const customer: SelectType = {
          value: x.customerId,
          label: x.name,
        };
        return customer;
      });

      setCustomerList(customersResult);
    });
  }, []);

  // getProjects
  useEffect(() => {
    setProjectSelectedState(0);

    // show all projects
    api.get(`projects`).then(response => {
      if (customerSelectedState > 0) {
        const projectsByCustomer = response.data.filter(
          (x: any) =>
            x.customer ===
            customerList.find(y => y.value === customerSelectedState)?.label,
        );
        const projectsData: SelectType[] = projectsByCustomer.map(
          (project: any) => {
            const projectToSelect = {
              label: project.name,
              value: project.projectId,
            };
            return projectToSelect;
          },
        );
        setProjectListSelect(projectsData);
      } else {
        const projectsData: SelectType[] = response.data?.map(
          (project: any) => {
            const projectToSelect = {
              label: project.name,
              value: project.projectId,
            };
            return projectToSelect;
          },
        );
        setProjectListSelect(projectsData);
      }
    });
  }, [customerList, customerSelectedState]);

  // getSurveysByProject
  useEffect(() => {
    setSurveySelectedState(0);
    let surveysFiltered: any[] = [];
    if (projectSelectedState) {
      api.get(`/surveys/project/${projectSelectedState}`).then(response => {
        if (response.data) {
          surveysFiltered = response.data;

          const surveysSelect: SelectType[] = surveysFiltered.map(
            (survey: any) => {
              const surveySelect: SelectType = {
                label: survey.name,
                value: survey.surveyId,
              };
              return surveySelect;
            },
          );

          setSurveyListSelect(surveysSelect);
        }
      });
    } else {
      api.get(`/surveys/`).then(response => {
        if (response.data) {
          surveysFiltered = response.data;

          const surveysSelect: SelectType[] = surveysFiltered.map(
            (survey: any) => {
              const surveySelect: SelectType = {
                label: survey.name,
                value: survey.surveyId,
              };
              return surveySelect;
            },
          );

          setSurveyListSelect(surveysSelect);
        }
      });
    }
  }, [projectSelectedState]);

  // get Access by survey
  useEffect(() => {
    setAccessSelectedState(0);

    if (surveySelectedState > 0) {
      api.get(`/accesses/survey/${surveySelectedState}`).then(response => {
        setAccessFullList(
          response.data?.map((access: any) => {
            return {
              accessId: access.accessId,
              accessName: access.name,
              segments: access.segments,
            };
          }),
        );

        const accessesList = response.data?.map((x: any) => {
          const access: SelectType = {
            value: x.accessId,
            label: x.name,
          };
          return access;
        });

        accessesList.push({ label: 'All', value: 0 });

        setAccessList(accessesList);
      });
    }
  }, [surveySelectedState]);

  // all segments ---
  useEffect(() => {
    api.get(`/segments`).then(response => {
      const segmentsResult = response.data.map((x: any) => {
        const segment: SelectType = {
          value: x.segmentId,
          label: x.name,
        };
        return segment;
      });

      segmentsResult.push({ label: 'All', value: 0 });

      if (accessSelectedState > 0) {
        const listSegmentsByAccess = accessFullList.find(
          x => x.accessId === accessSelectedState,
        )?.segments;

        const segmentsFilteredByAccess = segmentsResult.filter(
          (x: any) => listSegmentsByAccess?.includes(x.label) || x.value === 0,
        );

        setSegmentList(segmentsFilteredByAccess);
      } else {
        setSegmentList(segmentsResult);
      }
    });
  }, [accessFullList, accessSelectedState]);

  const handleSegmentSelectChange = useCallback((segment: any) => {
    setSegmentSelectedState(segment.value);
  }, []);

  const handleAccessSelectChange = useCallback((access: any) => {
    setAccessSelectedState(access.value);
    setSegmentSelectedState(0);
  }, []);

  const handleSelectedSurveyChange = useCallback((survey: any) => {
    setSurveySelectedState(survey.value);
    setSegmentSelectedState(0);
    setAccessSelectedState(0);
    setProgress(3);
  }, []);

  const handleSelectedProjectListChange = useCallback((project: any) => {
    setProjectSelectedState(project.value);
    setSurveySelectedState(0);
    setSegmentSelectedState(0);
    setAccessSelectedState(0);
    setProgress(2);
  }, []);

  const handleSelectedCustomerListChange = useCallback((customer: any) => {
    setCustomerSelectedState(customer.value);
    setProjectSelectedState(0);
    setSurveySelectedState(0);
    setSegmentSelectedState(0);
    setAccessSelectedState(0);
    setProgress(1);
  }, []);

  const handleValueChange = (
    selectTypes: SelectType[],
    value: number,
  ): SelectType => {
    return {
      label: selectTypes.find(x => x.value === value)?.label ?? 'Select...',
      value,
    };
  };

  return (
    <Container>
      <Content>
        {!props.isEdit && (
          <ProgressStepBar
            steps={steps}
            activeStep={progress}
          ></ProgressStepBar>
        )}
        <Form
          ref={props.formRef}
          initialData={props.initialData}
          onSubmit={props.handleSubmit}
        >
          <h2>Name</h2>
          <Input
            type="text"
            name="name"
            placeholder="Enter a name to identify this Link (used only for MAFFO)"
          />
          <br />
          <h3>Customer</h3>
          <Select
            name="customerId"
            options={customerList}
            onChange={e => handleSelectedCustomerListChange(e)}
            value={handleValueChange(customerList, customerSelectedState)}
          />

          {customerSelectedState > 0 && (
            <>
              <br />
              <h3>Project</h3>
              <Select
                name="projectId"
                options={projectListSelect}
                onChange={e => handleSelectedProjectListChange(e)}
                value={handleValueChange(
                  projectListSelect,
                  projectSelectedState,
                )}
              />
            </>
          )}

          {projectSelectedState > 0 && (
            <>
              <br />
              <h3>Survey</h3>
              <Select
                name="surveyId"
                ref={surveySelectField}
                options={surveyListSelect}
                onChange={e => handleSelectedSurveyChange(e)}
                value={handleValueChange(surveyListSelect, surveySelectedState)}
              />
            </>
          )}

          {surveySelectedState > 0 && (
            <>
              <br />
              <h3>Access</h3>
              <Select
                name="accessId"
                options={accessList}
                onChange={e => handleAccessSelectChange(e)}
                value={handleValueChange(accessList, accessSelectedState)}
              />

              <br />
              <h3>Segment</h3>
              <Select
                name="segmentId"
                options={segmentList}
                onChange={e => handleSegmentSelectChange(e)}
                value={handleValueChange(segmentList, segmentSelectedState)}
              />

              <Button type="submit" width="200px">
                {props.buttonText}
              </Button>
            </>
          )}
        </Form>
      </Content>
    </Container>
  );
};

export default ParticipationLinkForm;
