import styled from 'styled-components';

export const Content = styled.div`
  span {
    font-size: 1.2em;
    /* font-weight: bold; */
  }
`;

export const Container = styled.div`
  margin: 0px 0 0 45px;
  position: relative;
  top: 190px;
  padding: 20px;
  width: 60%;
  background-color: rgb(255, 255, 255, 0.8);
`;
