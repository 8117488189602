/* eslint-disable eqeqeq */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import FormAccess from '../Form';
import BackButton from '../../../components/BackButton';

interface Placeholder {
  replaceValue: string;
  searchValue: string;
}
interface Access {
  accessId: number;
  surveyId: number;
  name: string;
  segments: string[];
  isLocked: boolean;
  results: Result[];
  surveyName: string;
  placeholders: Placeholder[];
  subgroup: string;
  projectName?: string;
  subGroupSurvey?: string;
  surveys: any;
}

interface AccessForm {
  name: string;
  segments: string[];
  placeholders: string;
  isLocked: boolean;
  selectedLanguage: number;
  segmentsAux: string;
}

interface Result {
  resultId: number;
  code: string;
  createdByUser: boolean;
  languageId: number;
}

interface ParamTypes {
  accessId: string;
  surveyId: string;
}
interface SelectProps {
  value: number;
  label: string;
}

interface CodeLink {
  resultId: number;
  code: string;
  url: string;
  createdByUser: boolean;
  surveyId: number;
  surveyName: string;
  segmentNames: string;
  linkName: string;
  subgroup: string;
  accessId: number;
  accessName: string;
  language: string;
  languageId: number;
}

const EditAccess: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { accessId, surveyId } = useParams<ParamTypes>();
  const [access, setAccess] = useState<Access>();
  const [surveyIsLocked, setSurveyIsLocked] = useState<boolean>(false);
  const [surveyIsTemplate, setSurveyIsTemplate] = useState<boolean>(false);
  const [languages, setLanguages] = useState<SelectProps[]>([]);

  const [codeLinks, setCodeLinks] = useState<CodeLink[]>([]);

  const history = useHistory();

  const getAccessData = useCallback(
    async (languagesParam, accessParam, languageCodeListLocal) => {
      const localCodeLinks: CodeLink[] = [];

      if (accessParam && accessParam?.results) {
        await accessParam.results.forEach(async (result: Result) => {
          let languageName: string | undefined = '';
          if (languagesParam) {
            languageName = await languagesParam.find(
              (language: SelectProps) => language.value == result.languageId,
            )?.label;
          }

          const langCode = languageName
            ? languageCodeListLocal.find(
                (x: any) => x.value === result.languageId,
              )?.label
            : 'de';

          localCodeLinks.push({
            resultId: result.resultId,
            createdByUser: result.createdByUser,
            code: result.code,
            url: languageName
              ? `https://${window.location.hostname}/questionnaire/${
                  accessParam?.accessId
                }/${result.code}?lang=${result.languageId}&langCode=${
                  langCode ?? 'de'
                }`
              : `https://${window.location.hostname}/questionnaire/${accessParam?.accessId}/${result.code}`,
            linkName: (accessParam && accessParam.name) ?? '',
            segmentNames: JSON.stringify(accessParam?.segments ?? ''),
            surveyName: accessParam?.surveyName ?? '',
            surveyId: accessParam?.surveyId ?? 0,
            subgroup: accessParam?.subGroupSurvey ?? '',
            accessId: accessParam?.accessId ?? 0,
            language: languageName ?? '',
            languageId: result.languageId ?? 0,
            accessName: accessParam.name,
          });
        });
      }

      setCodeLinks(localCodeLinks);
    },
    [],
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      let accessLocal: Access = {} as Access;
      let languagesLocal: SelectProps[] = [];
      let surveyLanguagesLocal: number[] = [];
      let defaultLanguageSurvey = 0;
      await api.get(`/accesses/${accessId}`).then(response => {
        setAccess(response.data);
        accessLocal = response.data;
      });

      await api.get(`/surveys/${surveyId}`).then(surveyResponse => {
        if (
          surveyResponse &&
          surveyResponse.data &&
          surveyResponse.data?.isLocked === true
        ) {
          setSurveyIsLocked(true);
        } else {
          setSurveyIsLocked(false);
        }

        if (
          surveyResponse &&
          surveyResponse.data &&
          surveyResponse.data?.isTemplate === true
        ) {
          setSurveyIsTemplate(true);
        } else {
          setSurveyIsTemplate(false);
        }

        if (surveyResponse && surveyResponse.data) {
          surveyLanguagesLocal = surveyResponse.data.languages ?? [];

          defaultLanguageSurvey = surveyResponse.data?.defaultLanguageId;
          surveyLanguagesLocal.push(defaultLanguageSurvey);
        }
      });

      const languagesDropDown: SelectProps[] = [];
      const listLanguageCode: SelectProps[] = [];
      const valuesToSearch = JSON.stringify(surveyLanguagesLocal)
        ?.replace(/]/g, '')
        ?.replace(/\[/g, '')
        ?.replace(/"/g, '');

      await api.get(`languages/list/${valuesToSearch}`).then(response => {
        response.data.forEach((e: any) => {
          const dataRowDropDown = {
            value: e?.languageId,
            label: e?.languageId === 1 ? 'German (Default)' : e?.name,
          };
          languagesDropDown.push(dataRowDropDown);
          listLanguageCode.push({
            value: e?.languageId,
            label: e?.languageCode,
          });
        });
        languagesLocal = languagesDropDown.sort((a, b) => a.value - b.value);
        setLanguages(languagesDropDown);
      });

      await getAccessData(languagesLocal, accessLocal, listLanguageCode);
    };
    fetchData();
  }, [accessId, getAccessData, surveyId]);

  const handleSubmit = useCallback(
    async (data: AccessForm) => {
      try {
        formRef.current?.setErrors({});

        const accessNew = {
          name: data.name,
          segments: JSON.parse(data.segmentsAux),
          placeholders: JSON.parse(data.placeholders),
          isLocked: data.isLocked,
        };

        const schema = Yup.object().shape({
          name: Yup.string().required('The name is required'),
          segments: Yup.array(),
        });

        await schema.validate(accessNew, {
          abortEarly: false,
        });

        if (surveyIsLocked) {
          addToast({
            type: 'error',
            title: 'Survey Locked',
            description:
              'You can not save changes because this survey is locked.',
          });
        } else {
          await api.put(`/accesses/${accessId}`, accessNew);

          history.push({
            pathname: `/surveys/${surveyId}/accesses`,
            state: {
              pageTitle: `${access?.projectName} - ${access?.surveyName} - ${access?.subGroupSurvey}`,
            },
          });

          addToast({
            type: 'success',
            title: 'Success',
            description: 'The access was saved successfully!',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while editing the access, please try again.',
        });
      }
    },
    [
      surveyIsLocked,
      addToast,
      accessId,
      access?.projectName,
      access?.surveyName,
      access?.subGroupSurvey,
      history,
      surveyId,
    ],
  );

  const handleRemoveCode = useCallback(
    async (row: Result) => {
      if (surveyIsLocked) {
        addToast({
          type: 'error',
          title: 'Survey Locked',
          description:
            'You can not remove codes because this survey is locked.',
        });
      } else {
        try {
          let accessLocal: Access = {} as Access;
          const segmentsAux =
            JSON.parse(formRef.current?.getFieldValue('segmentsAux')) ?? [];

          // eslint-disable-next-line
          if (window.confirm('Are you sure you want to delete this code?')) {
            await api.delete(`/results/${row.resultId}`);
            await api.get(`/accesses/${accessId}`).then(async response => {
              const accessUpdated: Access = {
                ...response.data,
                segments: segmentsAux,
              };

              setAccess(accessUpdated);
              accessLocal = accessUpdated;

              const languagesLocal: SelectProps[] = [];
              let valuesToSearch = JSON.stringify(
                accessLocal.surveys[0]?.languages ?? [1],
              )
                ?.replace(/]/g, '')
                ?.replace(/\[/g, '')
                ?.replace(/"/g, '');

              const listLanguageCode: SelectProps[] = [];
              if (!valuesToSearch) valuesToSearch = '1'; // 1==German (Default Language);
              await api
                .get(`languages/list/${valuesToSearch}`)
                .then(responseLanguages => {
                  responseLanguages.data.forEach((e: any) => {
                    const langs = {
                      value: e?.languageId,
                      label: e?.name,
                    };
                    languagesLocal.push(langs);
                    listLanguageCode.push({
                      value: e?.languageId,
                      label: e?.languageCode,
                    });
                  });
                });

              await getAccessData(
                languagesLocal,
                accessLocal,
                listLanguageCode,
              );
            });

            addToast({
              type: 'success',
              title: 'Success',
              description: 'The code was removed successfully!',
            });
          }
        } catch (err) {
          console.log(`err`, err);
          addToast({
            type: 'error',
            title: 'Registration Error',
            description:
              'An error occurred while deleting the code, please try again.',
          });
        }
      }
    },
    [accessId, addToast, getAccessData, surveyIsLocked],
  );

  const handleCreateCode = useCallback(() => {
    if (surveyIsLocked) {
      addToast({
        type: 'error',
        title: 'Survey Locked',
        description: 'You can not create code because this survey is locked.',
      });
    } else {
      try {
        const numberOfCodes = formRef.current?.getFieldValue('numberCodes');
        const languageIdSelected = formRef.current?.getFieldValue('language');
        const segmentsAux =
          JSON.parse(formRef.current?.getFieldValue('segmentsAux')) ?? [];

        if (numberOfCodes) {
          addToast({
            type: 'info',
            title: 'Info',
            description: 'The codes are being created, wait for completion.',
          });

          api
            .post(`/results/createCodes/${numberOfCodes}`, {
              accessId,
              surveyId,
              testMode: false,
              languageId: languageIdSelected !== 0 ? languageIdSelected : null,
            })
            .then(response => {
              api.get(`/accesses/${accessId}`).then(async responseAccess => {
                const accessUpdated: Access = {
                  ...responseAccess.data,
                  segments: segmentsAux,
                };

                setAccess(accessUpdated);

                const valuesToSearch = JSON.stringify(
                  responseAccess.data.surveys[0]?.languages,
                )
                  ?.replace(/]/g, '')
                  ?.replace(/\[/g, '')
                  ?.replace(/"/g, '');

                const listLanguageCode: SelectProps[] = [];
                await api
                  .get(
                    `languages/list/${
                      valuesToSearch && valuesToSearch !== ''
                        ? valuesToSearch
                        : '1'
                    }`,
                  )
                  .then(responseLanguages => {
                    responseLanguages.data.forEach((e: any) => {
                      listLanguageCode.push({
                        value: e?.languageId,
                        label: e?.languageCode,
                      });
                    });
                  });

                getAccessData(languages, responseAccess.data, listLanguageCode);
              });

              formRef.current?.setFieldValue('numberCodes', '');
              formRef.current?.setFieldValue('language', '');

              addToast({
                type: 'success',
                title: 'Success',
                description: 'The codes were created successfully!',
              });
            });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred =hile creating the codes, please try again.',
        });
      }
    }
  }, [surveyIsLocked, addToast, accessId, surveyId, getAccessData, languages]);

  return (
    <>
      <BackButton />

      <h1>Edit {access?.name}</h1>
      <h2>
        {access?.projectName} - {access?.surveyName} - {access?.subGroupSurvey}
      </h2>

      <FormAccess
        formRef={formRef}
        handleSubmit={handleSubmit}
        handleRemoveCode={handleRemoveCode}
        handleCreateCode={handleCreateCode}
        initialData={access}
        languages={languages}
        codeLinks={codeLinks}
        buttonText="Save"
        isTemplate={surveyIsTemplate}
      />
    </>
  );
};

export default EditAccess;
