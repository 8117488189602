import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import ParticipationLinkForm from '../Form';

interface ParticipationLink {
  name: string;
  customerId: number;
  projectId: number;
  surveyId: number;
  accessId: number;
  segmentId: number;
}

const CreatePartcipationLink: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ParticipationLink) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Name is required'),
          customerId: Yup.number().required('Customer is required'),
          projectId: Yup.number().required('Project required'),
          surveyId: Yup.number().required('Survey is required'),
          accessId: Yup.number().required('Access is required'),
          segmentId: Yup.number().required('Segment is required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/participationLinks', data);

        const redirectUrl = '/participationLink';

        history.push(redirectUrl);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The link was created successfully!',
        });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating the text, please try again.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <h1>New customer link</h1>

      <ParticipationLinkForm
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Create"
      />
    </>
  );
};

export default CreatePartcipationLink;
