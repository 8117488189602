// check if survey element is a question by ElementType
export default function getValidationQuestionByElementType(
  elementType: string,
): boolean {
  switch (elementType) {
    case 'dropdown':
    case 'ranking':
    case 'radiogroup':
    case 'barrating':
    case 'ebemotionsratings':
    case 'funnyemotionsratings':
    case 'rating':
    case 'ebslider':
    case 'ebsliderpolarity':
    case 'ebverticalpolarity':
    case 'comment':
    case 'text':
    case 'checkbox':
      return true;
    default:
      return false;
  }
}
